import { request } from "../../../utils/request";
import * as _ from 'underscore';

export const GET_CITIES_REQUEST = "GET_CITIES_REQUEST";
export const GET_CITIES_SUCCESS = "GET_CITIES_SUCCESS";
export const GET_CITIES_ERROR = "GET_CITIES_ERROR";

export const GET_RESTAURANTS_REQUEST = "GET_RESTAURANTS_REQUEST";
export const GET_RESTAURANTS_SUCCESS = "GET_RESTAURANTS_SUCCESS";
export const GET_RESTAURANTS_ERROR = "GET_RESTAURANTS_ERROR";

export const GET_STATISTICS_REQUEST = "GET_STATISTICS_REQUEST";
export const GET_STATISTICS_SUCCESS = "GET_STATISTICS_SUCCESS";
export const GET_STATISTICS_ERROR = "GET_STATISTICS_ERROR";

export const GET_DATA_FOR_MAIN_PAGE_REQUEST = "GET_DATA_FOR_MAIN_PAGE_REQUEST";
export const GET_DATA_FOR_MAIN_PAGE_SUCCESS = "GET_DATA_FOR_MAIN_PAGE_SUCCESS";
export const GET_DATA_FOR_MAIN_PAGE_ERROR = "GET_DATA_FOR_MAIN_PAGE_ERROR";

export const getRestaurants = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: GET_CITIES_REQUEST
        });

        let response = await request("WinchController", "RestaurantsInCities");

        let cities = _.map(_.map(response.data.RestaurantsInCities, (index, key)=>{
            return [key, index];
        }), (city)=>{
            return {
                id: city[0],
                name: city[1].CityName,
                restaurants: city[1].restaurants,
                active: window.dataCity() ? parseInt(window.dataCity().id) === parseInt(city[0]) ? true : false : null
            }
        });

        dispatch({
            type: GET_CITIES_SUCCESS,
            payload: cities
        });
    } catch (err) {
        dispatch({
            type: GET_CITIES_ERROR,
            payload: err.message
        });
    }

    try {
        dispatch({
            type: GET_RESTAURANTS_REQUEST
        });

        let localCurrentCity =
            JSON.parse(localStorage.getItem("currentCity")) || "";
        let city = getState().main.cities.find(
            city => city.id === localCurrentCity.id
        );
        let restaurants = Object.entries(city.restaurants)
            .map(restaurant => ({
                id: restaurant[0],
                ...restaurant[1]
            }))
            .reverse();

        dispatch({
            type: GET_RESTAURANTS_SUCCESS,
            payload: restaurants
        });
    } catch (err) {
        dispatch({
            type: GET_RESTAURANTS_ERROR,
            payload: err.message
        });
    }
};

export const getDataForMainPage = () => async (dispatch) => {
    try {
        dispatch({
            type: GET_DATA_FOR_MAIN_PAGE_REQUEST,
        });

        let data = {
            city: {
                id: JSON.parse(localStorage.getItem('currentCity'))
                  ? JSON.parse(localStorage.getItem('currentCity')).id
                  : '18',
            },
        };

        let response = await request("DataForMainPage", "get", data);

        dispatch({
            type: GET_DATA_FOR_MAIN_PAGE_SUCCESS,
            payload: response.data,
        });
    } catch (err) {
        dispatch({
            type: GET_DATA_FOR_MAIN_PAGE_ERROR,
            payload: err.message,
        });
    }
};
