import { createGlobalStyle } from 'styled-components';
import styled from 'styled-components';

// Глобальные стили CSS
export const GlobalStyle = createGlobalStyle`
  /* Переменные */
  :root {
    --bg-color: #f8f8f8;
    --color-accent: #EA394B;
    --color-font: #2C2E32;
    --radius-small: 8px;
    --radius-medium: 16px;
    --radius-large: 32px;
    --shadow-contour: 0 0 5px 0 rgba(0, 0, 0, .5);
    --shadow-small: 0 0 10px 3px rgba(0, 0, 0, .10);
    --shadow-medium: 0 0 15px 6px rgba(0, 0, 0, .10);
    --shadow-large: 0 0 20px 9px rgba(0, 0, 0, .10);

    --font-size: 18px;
  }

  /* Шрифты */
  @font-face {
    font-family: 'Montserrat';
    src: url('/public/fonts/Montserrat-VariableFont_wght.ttf') format('truetype');
    font-weight: 100 900;
    font-display: swap;
  }

  /* Обнуления */
  *, *::before, *::after {
    padding: 0;
    margin: 0;
    border: 0;
    box-sizing: border-box;
  }
  a {
    text-decoration: none;
    cursor: pointer;
  }
  ul, ol, li {
    list-style: none;
  }
  img {
    vertical-align: top;
  }
  button {
    background: transparent;
    cursor: pointer;
  }
  h1, h2, h3, h4, h5, h6 {
    font-weight: inherit;
    font-size: inherit;
  }
  html, body {
    height: 100%;
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-size: var(--font-size);
    line-height: 1;
  }
  * {
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background-color:#aaaaaa;
      border-radius: 5px;
      transition: all 0.3s ease;
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
      border: 1px solid transparent;
    }
    &:hover::-webkit-scrollbar-thumb {
      background-color: #999999;
    }
  }
`;

export const Wrapper = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
  height: 100%;
`;

export const Content = styled.div`
	padding-top: 120px;
	display: flex;
	flex-direction: column;
	width: 100%;
	min-height: 100vh;
	background: var(--bg-color);
`;

export const Section = styled.div`
  position: relative;
  padding: 32px;
  background: #ffffff;
  border-top-left-radius: 48px;
  border-top-right-radius: 48px;
  border-bottom-left-radius: 48px;
  border-bottom-right-radius: 48px;
  overflow: hidden;
`;

export const SectionTop = styled(Section)`
  padding: 64px 0 32px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 48px;
  border-bottom-right-radius: 48px;
`;

export const SectionBottom = styled(Section)`
  margin-top: 32px;
  padding: 32px 0 64px;
  border-top-left-radius: 48px;
  border-top-right-radius: 48px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
`;

export const Container = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 10px;

  @media (max-width: 1440px) {
    max-width: 1200px;
  }

  @media (max-width: 1200px) {
    max-width: 1024px;
  }

  @media (max-width: 1024px) {
    max-width: 798px;
  }

  @media (max-width: 798px) {
    max-width: auto;
  }
`;
