import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Router from "./Router";
import Layout from "./Layout";
import { getAppSettings, setIsCityModalOpen } from "../redux/reducer/slices/commonSlice";
import { getProfile } from "../redux/reducer/slices/profileSlice";
import { getCompanies } from "../redux/reducer/slices/companiesSlice";
import { getOrders } from "../redux/reducer/slices/ordersSlice";

const App = () => {
    const dispatch = useDispatch();
    const { currentCity } = useSelector((state) => state.common);
    const { isAuth } = useSelector((state) => state.profile);

    console.log(isAuth, 'isAuth');

    useEffect(() => {
        dispatch(getAppSettings());

        if (!currentCity) {
            dispatch(setIsCityModalOpen());
        }

        if (isAuth) {
            dispatch(getProfile());
            dispatch(getCompanies());
            dispatch(getOrders());
        }
    }, [isAuth]);

    return (
        <Layout>
			<Router/>
        </Layout>
    );
};

export default App;
