import React from 'react';
import styled from 'styled-components';

const Title = ({ style, className, level = 1, align, children }) => {
    const Tag = `h${level}`;

    return (
        <StyledTitle
            as={Tag}
            style={style}
            className={className}
            level={level}
            align={align}
        >
            {children}
        </StyledTitle>
    );
};

export default Title;

const fontSize = (level) => {
    switch (level) {
        case 1:
            return {fontSize: '32px', fontWeight: '600'};
        case 2:
            return {fontSize: '24px', fontWeight: '600'};
        case 3:
            return {fontSize: '20px', fontWeight: '600'};
        case 4:
            return {fontSize: '18px', fontWeight: '600'};
        case 5:
            return {fontSize: '16px', fontWeight: '700'};
        default:
            return {fontSize: '32px', fontWeight: '600'};
    }
};

const StyledTitle = styled.span`
    font-size: ${props => fontSize(props.level).fontSize};
    font-weight: ${props => fontSize(props.level).fontWeight};
    text-align: ${props => props.align || 'left'};
    color: #2C2E32;
`;
