import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { request } from '../../../utils/request';

export const getOrders = createAsyncThunk(
    'order/getOrders',
    async (_, { rejectWithValue }) => {
        try {
            let response = await request("AppDinnerController", "GetOrders1");

            if (response.result) {
                console.log(response.data, 'res.data');
                return response.data;
            } else {
                return rejectWithValue(response.data);
            }
        } catch (error) {
            console.error(error);
            return rejectWithValue(error.response.data);
        }
    }
);

export const cancelOrder = createAsyncThunk(
    'order/cancelOrder',
    async (data, { rejectWithValue }) => {
        try {
            let response = await request('DinnerController', 'CancelOrder', data);

            if (response.result) {
                console.log(response.data, 'res.data');
                return response.data;
            } else {
                return rejectWithValue(response.data);
            }
        } catch (error) {
            console.error(error);
            return rejectWithValue(error.response.data);
        }
    }
);

export const getOrderReview = createAsyncThunk(
    'order/getOrderReview',
    async (data, { rejectWithValue }) => {
        try {
            let response = await request('ReviewsController', 'GetOrderReview', data);

            if (response.result) {
                console.log(response.data, 'res.data');
                return response.data;
            } else {
                return rejectWithValue(response.data);
            }
        } catch (error) {
            console.error(error);
            return rejectWithValue(error.response.data);
        }
    }
);

export const reviewOrder = createAsyncThunk(
    'order/reviewOrder',
    async (data, { rejectWithValue }) => {
        try {
            let response = await request('ReviewsController', 'SaveOrderReview', data);

            if (response.result) {
                console.log(response.data, 'res.data');
                return response.data;
            } else {
                return rejectWithValue(response.data);
            }
        } catch (error) {
            console.error(error);
            return rejectWithValue(error.response.data);
        }
    }
);

const initialState = {
    getOrdersStatus: null,
    orders: [],
    cancelOrderStatus: null,

    getOrderReviewStatus: null,
    orderReview: null,
    reviewOrderStatus: null,
};

const ordersSlice = createSlice({
    name: 'orders',
    initialState,
    extraReducers: (builder) => {
        builder
            // getOrders
            .addCase(getOrders.pending, (state) => {
                state.getOrdersStatus = 'pending';
            })
            .addCase(getOrders.fulfilled, (state, action) => {
                state.getOrdersStatus = 'fulfilled';
                state.orders = action.payload;
            })
            .addCase(getOrders.rejected, (state) => {
                state.getOrdersStatus = 'rejected';
            })

            // cancelOrder
            .addCase(cancelOrder.pending, (state) => {
                state.cancelOrderStatus = 'pending';
            })
            .addCase(cancelOrder.fulfilled, (state, action) => {
                state.cancelOrderStatus = 'fulfilled';
            })
            .addCase(cancelOrder.rejected, (state) => {
                state.cancelOrderStatus = 'rejected';
            })

            // getOrderReview
            .addCase(getOrderReview.pending, (state) => {
                state.getOrderReviewStatus = 'pending';
            })
            .addCase(getOrderReview.fulfilled, (state, action) => {
                state.getOrderReviewStatus = 'fulfilled';
                state.orderReview = action.payload;
            })
            .addCase(getOrderReview.rejected, (state) => {
                state.getOrderReviewStatus = 'rejected';
            })

            // reviewOrder
            .addCase(reviewOrder.pending, (state) => {
                state.reviewOrderStatus = 'pending';
            })
            .addCase(reviewOrder.fulfilled, (state, action) => {
                state.reviewOrderStatus = 'fulfilled';
            })
            .addCase(reviewOrder.rejected, (state) => {
                state.reviewOrderStatus = 'rejected';
            })
    },
    reducers: {
        setIsCityModalOpen: (state) => {
            state.isCityModalOpen = true;
        },
        setIsCityModalClose: (state) => {
            state.isCityModalOpen = false;
        },
        setCurrentCity: (state, action) => {
            state.currentCity = action.payload;
        },
    },
});

export const {
    setIsCityModalOpen,
    setIsCityModalClose,
    setCurrentCity,
} = ordersSlice.actions;

export default ordersSlice.reducer;