import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled, { keyframes } from "styled-components";
import Checkbox from "../../../components/Checkbox";
import SearchBar from "./components/SearchBar";
import Title from "../../../components/Title";
import { setIsCityModalOpen } from "../../../redux/reducer/slices/commonSlice";
import MapContainer from "./components/MapContainer";
import Input from "../../../components/Input";
import Flex from "../../../components/Flex";
import Button from "../../../components/Button";
import { sendGeocodeAddress, setGeocodeAddress, setGeocodeCoords } from "../../../redux/reducer/slices/mapSlice";
import { saveCompany, setIsAddressModalClose } from "../../../redux/reducer/slices/companiesSlice";

const AddressSelectorForm = () => {
    const dispatch = useDispatch();
    const { currentCity } = useSelector((state) => state.common);
    const { saveCompanyStatus } = useSelector((state) => state.companies);
    const { addressGeocodeObject } = useSelector((state) => state.map);
    const [step, setStep] = useState(1);
    const [isAddressIncorrect, setIsAddressIncorrect] = useState(false);
    const [info, setInfo] = useState({
        companyType: 1, // 1 - это компания, 2 - дом.
        name: '',
        entrance: '',
        flat: '',
        floor: '',
        comment: '',
    });

    const handleClickCity = () => {
        dispatch(setIsCityModalOpen());
    }

    const handleCheckboxChange = () => {
        setIsAddressIncorrect(!isAddressIncorrect);
    };

    const handleNext = () => {
        if (!addressGeocodeObject) {
            alert('Выберите адрес');
        } else {
            setStep(2);
        }
    };

    const handleTypeChange = (type) => {
        setInfo((prev) => ({
            ...prev,
            companyType: type,
            name: type === 2 ? 'Дом' : undefined,
        }));
    };

    const handleInputChange = (field, value) => {
        setInfo((prev) => ({
            ...prev,
            [field]: value,
        }));
    };

    const handleBack = () => {
        setStep(1);
        setInfo({
            companyType: 1,
            name: '',
            entrance: '',
            flat: '',
            floor: '',
            comment: '',
        })
        dispatch(setGeocodeAddress(null));
        dispatch(setGeocodeCoords(null));
        dispatch(sendGeocodeAddress(null));
    };

    const handleSaveAddress = () => {
        const requestData = {
            id: null,
            name: info.companyType === 1 ? (info.name || 'Компания') : 'Дом',
            address: {
                lat: addressGeocodeObject.lat,
                lng: addressGeocodeObject.lng,
                street: addressGeocodeObject.street,
                house: addressGeocodeObject.house,
                floor: info.floor || "",
                entrance: info.entrance || "",
                flat: info.flat || "",
                city: {
                    id: currentCity.id,
                    name: currentCity.name
                },
            },
            extra: {
              comment: info.comment || "",
            }
        };

        console.log(requestData);
        dispatch(saveCompany(requestData));
    };

    return (
        <>
            {step === 1 &&
                <Wrapper>
                    <Title style={{marginBottom: '32px'}} level={2}>Ваш город <span style={{borderBottom: '3px solid black', cursor: 'pointer'}} onClick={handleClickCity}>{currentCity ? currentCity.name : 'Тольятти'}</span></Title>
                    <AddressWrapper>
                        <SearchBar onClick={handleNext}/>
                        <CheckboxWrapper>
                            <Checkbox
                                checked={isAddressIncorrect}
                                onChange={handleCheckboxChange}
                            />
                            <span>Адрес определён некорректно</span>
                        </CheckboxWrapper>
                    </AddressWrapper>
                    <MapWrapper>
                        <MapContainer/>
                    </MapWrapper>
                </Wrapper>
            }
            {step === 2 &&
                <Wrapper>
                    <Title style={{marginBottom: '24px'}} level={2}>Добавить новый адрес</Title>

                    <Flex style={{marginBottom: '24px'}} gap='20px'>
                        {`${addressGeocodeObject && addressGeocodeObject.locality ? `${addressGeocodeObject.locality}, ` : ''}` +
                        `${addressGeocodeObject && addressGeocodeObject.street ? `${addressGeocodeObject.street}, ` : ''}` +
                        `${addressGeocodeObject && addressGeocodeObject.house ? `${addressGeocodeObject.house}` : ''}`}
                    </Flex>

                    <Flex style={{marginBottom: '24px'}} gap='20px'>
                        <Button type={info.companyType === 1 ? 'primary' : 'default'} onClick={() => handleTypeChange(1)}>Работа</Button>
                        <Button type={info.companyType === 2 ? 'primary' : 'default'} onClick={() => handleTypeChange(2)}>Дом</Button>
                    </Flex>

                    {info.companyType === 1 && (
                        <Input
                            style={{marginBottom: '16px'}}
                            value={info.name}
                            onChange={(value) => handleInputChange('name', value)}
                            placeholder="Ваша компания"
                        />
                    )}

                    <InlineGroup>
                        <Input
                            type="numeric"
                            value={info.entrance}
                            onChange={(value) => handleInputChange('entrance', value)}
                            placeholder="Подъезд"
                        />
                        <Input
                            type="numeric"
                            value={info.floor}
                            onChange={(value) => handleInputChange('floor', value)}
                            placeholder="Этаж"
                        />
                        <Input
                            type="numeric"
                            value={info.flat}
                            onChange={(value) => handleInputChange('flat', value)}
                            placeholder={info.companyType === 1 ? "Офис" : 'Кв.'}
                        />
                    </InlineGroup>

                    <Input
                        style={{marginTop: '16px'}}
                        type='textarea'
                        value={info.comment}
                        onChange={(value) => handleInputChange('comment', value)}
                        placeholder="Комментарий к адресу"
                    />

                    <Flex style={{marginTop: '24px'}} justify='space-between'>
                        <Button onClick={handleBack}>Назад</Button>
                        <Button type='primary' onClick={handleSaveAddress}>Сохранить</Button>
                    </Flex>
                </Wrapper>
            }
        </>
    );
};

export default AddressSelectorForm;

// Стили
const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 800px;
`;

const AddressWrapper = styled.div`
    margin-bottom: 16px;
`;

const CheckboxWrapper = styled.div`
    margin: 24px 0;
    display: flex;
    align-items: center;
    gap: 8px;
`;

const MapWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 500px;
    border: 1px solid #ccc;
    border-radius: 8px;
`;

const InlineGroup = styled.div`
    display: flex;
    gap: 16px;
    align-items: center;
`;

const grow = keyframes`
    from {
        transform: scale(0);
    }
    to {
        transform: scale(1);
    }
`;

const shrink = keyframes`
    from {
        transform: scale(1);
    }
    to {
        transform: scale(0);
    }
`;

const ToggleLabel = styled.label`
    flex: 1;
    display: flex;
    align-items: center;
    cursor: pointer;
    gap: 12px;
    font-size: 16px;
    font-weight: 500;
    color: ${({ active }) => (active ? '#2a2a2a' : '#ababab')};
    transition: color 0.3s ease;
`;

const RadioButton = styled.div`
    width: 32px;
    height: 32px;
    border: 1px solid ${({ active }) => (active ? '#EA394B' : '#dbdbdb')};
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: border-color 0.3s ease;

    &:before {
        content: '';
        width: 24px;
        height: 24px;
        background-color: #EA394B;
        border-radius: 50%;
        transform: scale(${({ active }) => (active ? 1 : 0)});
        animation: ${({ active }) => (active ? grow : shrink)} 0.3s ease-out;
    }
`;
