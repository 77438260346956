import { combineReducers } from '@reduxjs/toolkit';
import commonSlice from './slices/commonSlice';
import profileSlice from './slices/profileSlice';
import companiesSlice from './slices/companiesSlice';
import mapSlice from './slices/mapSlice';
import kitchensSlice from './slices/kitchensSlice';
import cartSlice from './slices/cartSlice';
import ordersSlice from './slices/ordersSlice';

import { reducer as formReducer } from 'redux-form';
import { headerReducer } from "./reducers/header";
import { mainReducer } from "./reducers/main";
import { modalReducer } from "./reducers/modal";
import { menuReducer } from "./reducers/menu";
import { personalReducer } from "./reducers/personal";
import { myOrdersReducer } from "./reducers/myOrders";
import interactiveMapPromoReducer from '../../interactiveMapPromoModule/features/interactiveMapPromoReducer';

const combineReducer = combineReducers({
    common: commonSlice,
    profile: profileSlice,
    companies: companiesSlice,
    map: mapSlice,
    kitchens: kitchensSlice,
    cart: cartSlice,
    orders: ordersSlice,

    header: headerReducer,
    main: mainReducer,
    modal: modalReducer,
    menu: menuReducer,
    personal: personalReducer,
    form: formReducer,
    myOrders: myOrdersReducer,
    
    interactiveMapPromo: interactiveMapPromoReducer,
});

export default combineReducer;
