import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    newOrderInfo: {},
    products: [],
    totalPrice: 0,
    
    // date: { dateString:"2025-01-22" },
    // owner: { id:"241851" },
    // period: { id:"1" },

    // company: { id:"72271" },
};

const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        updateNewOrderInfo: (state, action) => {
            state.newOrderInfo = {...state.newOrderInfo, ...action.payload};
        },
        addProductToCart: (state, action) => {
            const { product, quantity, modifiers, otherData } = action.payload;
            console.log(product, quantity, modifiers, otherData)
            const existingProduct = state.products.find(item => item.id === product.id);
        
            if (existingProduct) {
                existingProduct.amount = quantity ? quantity : existingProduct.amount + 1;
            } else {
                const otherKitchen = state.newOrderInfo?.owner?.id && otherData.owner.id !== state.newOrderInfo?.owner?.id;
                const otherDate = state.newOrderInfo?.date?.dateString && otherData.date.dateString !== state.newOrderInfo?.date?.dateString;

                if (otherKitchen) {
                    console.log('Товары из другой кухни');
                } else if (otherDate) {
                    console.log('Товары на другую дату');
                } else {
                    state.products.push({
                        ...product,
                        amount: quantity ? quantity : 1,
                        modifiers: modifiers ? modifiers : [],
                    });
                    state.newOrderInfo = {...state.newOrderInfo, ...otherData};
                }
            }
        
            state.totalPrice = state.products.reduce((acc, item) => {
                const basePrice = +item.price;
                const modifiersPrice = item.modifiers?.reduce((modAcc, mod) => modAcc + (+mod.price || 0), 0) || 0;
                const totalItemPrice = (basePrice + modifiersPrice) * item.amount;
                return acc + totalItemPrice;
            }, 0);
        },
        removeProductFromCart: (state, action) => {
            const { productId, removeAll } = action.payload;
            const existingProduct = state.products.find(item => item.id === productId);
        
            if (existingProduct) {
                if (removeAll) {
                    state.products = state.products.filter(item => !(item.id === productId));
                    if (state.products.length === 0) {
                        state.newOrderInfo = {};
                    }
                } else {
                    existingProduct.amount -= 1;
                }
            }
        
            state.totalPrice = state.products.reduce((acc, item) => {
                const basePrice = +item.price;
                const modifiersPrice = item.modifiers?.reduce((modAcc, mod) => modAcc + (+mod.price || 0), 0) || 0;
                const totalItemPrice = (basePrice + modifiersPrice) * item.amount;
                return acc + totalItemPrice;
            }, 0);
        },
        clearCart: (state) => {
            state.newOrderInfo = {};
            state.products = [];
            state.totalPrice = 0;
        },
    },
});

export const {
    updateNewOrderInfo,
    addProductToCart,
    removeProductFromCart,
    clearCart,
} = cartSlice.actions;

export default cartSlice.reducer;