import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setCurrentCity } from "../../../redux/reducer/slices/commonSlice";
import { changeActiveCompany, setActiveCompany, setIsAddressModalOpen } from "../../../redux/reducer/slices/companiesSlice";
import styled from "styled-components";
import Loader from "../../../components/Loader";
import Button from "../../../components/Button";
import { AddressIcon, CheckIcon } from "../../../components/Icons";

const AddressButton = () => {
    const dispatch = useDispatch();
    const { getCompaniesStatus, companies, activeCompany, changeActiveCompanyStatus } = useSelector((state) => state.companies);
    const [isShowQuestion, setIsShowQuestion] = useState(false);
    const [questionType, setQuestionType] = useState(""); // "" | "addAddress" | "confirmAddress" | "selectAddress"

    useEffect(() => {
        if (companies.length === 0) {
            setQuestionType("addAddress");
            setIsShowQuestion(true);
        } else if (companies.length > 0) {
            const matchCompany = companies.find(company => company.type === "1");
            if (matchCompany) {
                setQuestionType("confirmAddress");
                setIsShowQuestion(true);
            } else {
                setQuestionType("selectAddress");
                setIsShowQuestion(true);
            }
        }
    }, [companies]);

    const handleShowAddressList = () => {
        setQuestionType("selectAddress");
    };

    const handleAddAddress = () => {
        dispatch(setIsAddressModalOpen());
    };
    
    const handleChangeAddress = (company) => {
        dispatch(changeActiveCompany(company));
        setIsShowQuestion(false);
    };

    const handleCancel = () => {
        setIsShowQuestion(false);
    };

    return (
        <AddressSelectorWrapper>
            <StyledButton onClick={() => setIsShowQuestion(true)}>
                {getCompaniesStatus === 'pending' || changeActiveCompanyStatus === 'pending' ? (
                    <Loader/>
                ) : (
                    <>
                        {companies.length > 0 && activeCompany ? (
                            <ButtonContent>
                                <AddressIcon/>
                                <ButtonTitle>
                                    <span>{activeCompany.name && activeCompany.name}</span>
                                    <p>
                                        {activeCompany.address.street && `${activeCompany.address.street} `}
                                        {activeCompany.address.house && `${activeCompany.address.house}`}
                                        {activeCompany.address.floor && `, эт. ${activeCompany.address.floor},`}
                                        {activeCompany.address.flat && ` кв ${activeCompany.address.flat}`}
                                    </p>
                                </ButtonTitle>
                            </ButtonContent>
                        ) : (
                            <>
                                <AddressIcon/>
                                <p>Укажите адрес доставки</p>
                            </>
                        )}
                    </>
                )}
            </StyledButton>

            {getCompaniesStatus !== 'pending' && isShowQuestion && (
                <>
                    {questionType === "addAddress" ? (
                        <QuestionWrapper>
                            <Title style={{marginBottom: '10px'}}>Добавим адрес?</Title>
                            <Text>Добавив адрес доставки, вы увидите доступные кухни и условия доставки</Text>
                            <Actions>
                                <Button onClick={handleCancel}>Нет</Button>
                                <Button type="primary" onClick={handleAddAddress}>Да</Button>
                            </Actions>
                        </QuestionWrapper>
                    ) : questionType === "confirmAddress" && companies.length > 0 ? (
                        (() => {
                            const matchCompany = companies.find(company => company.type === "1");

                            return (
                                <QuestionWrapper>
                                    <Text>Заказ доставить на этот адрес?</Text>
                                    <Address>
                                        {matchCompany.address.street && `${matchCompany.address.street} `}
                                        {matchCompany.address.house && `${matchCompany.address.house}`}
                                        {matchCompany.address.floor && `, эт. ${matchCompany.address.floor},`}
                                        {matchCompany.address.flat && ` кв ${matchCompany.address.flat}`}
                                    </Address>
                                    <Actions>
                                        <Button onClick={handleShowAddressList}>Нет</Button>
                                        <Button type="primary" onClick={handleCancel}>Да</Button>
                                    </Actions>
                                </QuestionWrapper>
                            );
                        })()
                    ) : questionType === "selectAddress" ? (
                        <QuestionWrapper style={{padding: '0 0 24px'}}>
                            <List>
                                {companies.length > 0 ? (
                                    companies.map((company) => (
                                        <ListItem key={company.id} onClick={() => handleChangeAddress(company)}>
                                            <div>
                                                <span>{company.name && company.name}</span>
                                                <p>
                                                    {company.address.street && `${company.address.street} `}
                                                    {company.address.house && `${company.address.house}`}
                                                    {company.address.floor && `, эт. ${company.address.floor},`}
                                                    {company.address.flat && ` кв ${company.address.flat}`}
                                                </p>
                                            </div>
                                            {activeCompany && company.id === activeCompany.id &&
                                                <CheckIcon color={'#EA394B'}/>
                                            }
                                        </ListItem>
                                    ))
                                ) : (
                                    'У вас нет адресов'
                                )}
                            </List>
                            <Actions style={{padding: '0 32px'}}>
                                <Button style={{flex: 1}} onClick={handleAddAddress}>Новый адрес</Button>
                            </Actions>
                        </QuestionWrapper>
                    ) : null}
                </>
            )}
        </AddressSelectorWrapper>
    );
};

export default AddressButton;

const AddressSelectorWrapper = styled.div`
    position: relative;
    width: 100%;
    max-width: 380px;
`;

const StyledButton = styled(Button)`
    width: 100%;
    justify-content: flex-start;
`;

const ButtonContent = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
`;

const ButtonTitle = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    span {
        font-size: 16px;
        font-weight: 600;
    }
    p{
        font-size: 16px;
    }
`;

const QuestionWrapper = styled.div`
    position: absolute;
    top: 100%;
    left: 0;
    margin-top: 16px;
    padding: 24px 32px;
    width: fit-content;
    max-width: 380px;
    min-width: 100%;
    background: #ffffff;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    z-index: 10;
`;

const Title = styled.div`
    margin-bottom: 10px;
    font-size: 24px;
    font-weight: 500;
`;

const Text = styled.div`
    font-size: 15px;
    font-weight: 400;
`;

const Address = styled.div`
    margin-top: 10px;
    font-size: 24px;
    font-weight: 500;
`;

const List = styled.div`
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    max-height: 300px;
    overflow-y: auto;
`;

const ListItem = styled.div`
    min-height: 66px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
    padding: 0 32px;
    cursor: pointer;
    transition: all 0.3s ease;
    span {
        font-size: 16px;
        font-weight: 600;
    }
    p{
        font-size: 16px;
    }
    &:hover {
        background: #F3F3F3;
    }
`;

const Actions = styled.div`
    margin-top: 16px;
    display: flex;
    gap: 16px;
`;

