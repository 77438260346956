import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Button from './Button';

const InputWithButton = ({ ref, icon: IconComponent, placeholder, initialValue = '', buttonText, onClick }) => {
  const [inputValue, setInputValue] = useState(initialValue);

  useEffect(() => {
    setInputValue(initialValue);
  }, [initialValue]);

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      onClick(inputValue);
    }
  };

  const handleClick = () => {
    onClick(inputValue);
  };

  return (
    <Wrapper>
      <InputWrapper>
        {IconComponent && <IconContainer>{<IconComponent />}</IconContainer>}
        <StyledInput
          ref={ref}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={handleKeyPress}
          placeholder={placeholder}
        />
      </InputWrapper>
      <StyledButton type='primary' onClick={handleClick}>
        {buttonText}
      </StyledButton>
    </Wrapper>
  );
};

export default InputWithButton;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  border: solid 1px #E0E0E0;
  border-radius: 16px;
  padding: 0 24px;
  height: 60px;
  flex-grow: 1;
`;

const IconContainer = styled.div`
  margin-right: 8px;
`;

const StyledInput = styled.input`
  border: none;
  outline: none;
  width: 100%;
  min-width: 220px;
  font-size: 18px;
`;

const StyledButton = styled(Button)`
  margin-left: -20px;
`;
