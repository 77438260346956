import {request} from "../../../utils/request";
import {logger} from "redux-logger/src";

export const TOGGLE_CANCEL_MODAL = 'TOGGLE_CANCEL_MODAL'

export const START_CANCEL_ORDER = 'START_CANCEL_ORDER'
export const SUCCESS_CANCEL_ORDER = 'SUCCESS_CANCEL_ORDER'
export const ERROR_CANCEL_ORDER = 'ERROR_CANCEL_ORDER'
export const RESET_MODAL = 'RESET_MODAL'

export const toggleCancelModal = payload => ({type: TOGGLE_CANCEL_MODAL, payload})

export const startCanceling = () => ({type: START_CANCEL_ORDER})
export const successCanceling = payload => ({type: SUCCESS_CANCEL_ORDER, payload})
export const errorCanceling = payload => ({type: SUCCESS_CANCEL_ORDER, payload})
const resetModal = () => ({type: RESET_MODAL})
export const cancelOrder = payload => {
    return (dispatch) => {
        dispatch(startCanceling())
        cancelOrderHandle(payload)
            .then(res => {
                dispatch(successCanceling(payload))
                setTimeout(() => {
                    dispatch(toggleCancelModal())
                    dispatch(resetModal())
                }, 1000)
            })
            .catch(res => {dispatch(errorCanceling(res.message))})
    }
}


//local handlers
const cancelOrderHandle = payload => {
    return request("DinnerController", "SaveOrder", {
        owner: {
            id: payload.ownerId
        },
        order: {
            id: payload.orderId
        },
        products: [],
    })
}