import React from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setIsAuthModalOpen } from "../../redux/reducer/slices/profileSlice";
import styled from "styled-components";
import logo from "../../images/logo.svg";
import { CartIcon, ProfileIcon, SearchIcon } from '../../components/Icons';
import InputWithButton from "../../components/InputWithButton";
import AddressButton from "./components/AddressButton";
import Button from "../../components/Button";

const Header = () => {
	const dispatch = useDispatch();
	const { isAuthModalOpen, isAuth, profile } = useSelector((state) => state.profile);
	const { totalPrice } = useSelector((state) => state.cart);

	const handleSearch = () => {
		alert('Нужна функция для серверного поиска по кухням')
	}
	
	const handleClickAuth = () => {
		console.log(isAuthModalOpen, 'isAuthModalOpen');
		dispatch(setIsAuthModalOpen());
	}

	return (
		<HeaderWrapper>
			<HeaderLeft>
				<HeaderLogo>
					<Link to={'/'}>
						<img alt ='Сытый' src ={logo}/>
					</Link>
				</HeaderLogo>

				<InputWithButton
					icon={SearchIcon}
					placeholder="Поиск блюд и кухонь"
					buttonText="Найти"
					onClick={handleSearch}
				/>

				{isAuth && (
					<AddressButton/>
				)}
			</HeaderLeft>

			<HeaderRight>
				{isAuth ? (
					<>
						<Link to='/cart'>
							<Button type='primary'>
								<CartIcon color='#ffffff'/>
								{totalPrice > 0 ? `${totalPrice} ₽` : 'Корзина'}
							</Button>
						</Link>
						<Link to="/profile">
							<Button>
								<ProfileIcon/>
								{profile && profile.client && profile.client.name
									? profile.client.name
									: profile && profile.client && profile.client.phone
									? profile.client.phone
									: 'Профиль'
								}
							</Button>
						</Link>
					</>
				) : (
					<Button type='primary' onClick={handleClickAuth}>
						<ProfileIcon color='#ffffff'/>
						Войти
					</Button>
				)}
			</HeaderRight>
		</HeaderWrapper>
	);
}

export default Header;

const HeaderWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 900;
  display: flex;
  justify-content: space-between;
  gap: 60px;
  padding: 10px 40px;
  background-color: #ffffff;
  height: 120px;
`;

const HeaderLeft = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  gap: 20px;
`;

const HeaderLogo = styled.div`
  width: fit-content;
  margin-right: 35px;
`;

const HeaderRight = styled.div`
  flex: 0;
  display: flex;
  align-items: center;
  gap: 20px;
`;
