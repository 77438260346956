import {
	AUTH_MODAL_TOGGLE,
    TEMPORARY_VALUE,
	INFO_VIEW_TOGGLE,
} from "../actions/ModalActions";

const initialState = {
	authModalIsOpen: false,
	temporaryStorageFacility: [],
	viewInfoPerformance: false,
	modalDays: false,
	modalCompanies: false,
};

export function modalReducer(state = initialState, action) {
	switch (action.type) {
		case AUTH_MODAL_TOGGLE:
			return {
				...state,
				authModalIsOpen: !state.authModalIsOpen
			};
        case TEMPORARY_VALUE:
            return {
                ...state,
				temporaryStorageFacility: action.payload
            };
		case INFO_VIEW_TOGGLE:
			return {
				...state,
				viewInfoPerformance: action.payload
			};

		default:
			return state;
	}
}






// import {
// 	CITY_MODAL_TOGGLE,
// 	AUTH_MODAL_TOGGLE,
// 	PROFILE_MODAL_TOGGLE,
// 	NO_AUTH_MODAL_TOGGLE,
// } from "../actions/ModalActions";
//
// const initialState = {
// 	cityModalIsOpen: false,
// 	profileModalIsOpen: false,
// 	productModalOpen: false,
// 	isNoAuthModalOpen: false
// };
//
// export function modalReducer(state = initialState, action) {
// 	switch (action.type) {
// 		case CITY_MODAL_TOGGLE:
// 			return {
// 				...state,
// 				cityModalIsOpen: !state.cityModalIsOpen
// 			};
//
// 		case AUTH_MODAL_TOGGLE:
// 			return {
// 				...state,
// 				authModalIsOpen: !state.authModalIsOpen
// 			};
//
// 		case PROFILE_MODAL_TOGGLE:
// 			return {
// 				...state,
// 				profileModalIsOpen: !state.profileModalIsOpen
// 			};
//
// 		case NO_AUTH_MODAL_TOGGLE:
// 			return {
// 				...state,
// 				isNoAuthModalOpen: !state.isNoAuthModalOpen
// 			};
//
// 		default:
// 			return state;
// 	}
// }
