import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setIsCityModalClose } from "../redux/reducer/slices/commonSlice";
import { setIsAuthModalClose, setIsAuthQuestionModalClose } from '../redux/reducer/slices/profileSlice';
import { setIsAddressModalClose } from "../redux/reducer/slices/companiesSlice";
import { GlobalStyle, Wrapper, Content } from './styles';
// import { YMInitializer } from 'react-yandex-metrika';
import Header from "../containers/header/Header";
import Footer from "../containers/footer/Footer";
import Cookie from "../components/Cookie";
import FloatingSocialButtons from "../components/FloatingSocialButtons";
import { Message } from "../components/Message";
import Modal from "../components/Modal";
import AuthForm from "../containers/forms/authForm/AuthForm";
import SelectCityForm from "../containers/forms/selectCityForm/SelectCityForm";
import AddressSelectorForm from "../containers/forms/addressSelectorForm/AddressSelectorForm";
import AuthQuestionForm from "../containers/forms/authForm/AuthQuestionForm";

const Layout = ({ children }) => {
    const location = useLocation();
    const dispatch = useDispatch();
    const { isCityModalOpen } = useSelector((state) => state.common);
    const { isAuthModalOpen, isAuthQuestionModalOpen } = useSelector((state) => state.profile);
	const { isAddressModalOpen, saveCompanyStatus } = useSelector((state) => state.companies);

    useEffect(() => {
        if (saveCompanyStatus === 'fulfilled') {
            dispatch(setIsAddressModalClose());
        }
    }, [saveCompanyStatus]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    return (
        <Wrapper>
			<GlobalStyle/>
			{/* <YMInitializer
                accounts={[50456719]}
                options={{
                    clickmap: true,
                    trackLinks: true,
                    accurateTrackBounce: true,
                    webvisor: true,
                }}
                version="2"
            /> */}

            <Header/>

            <Content>
			    {children}
            </Content>

            <Footer/>

            <Modal isOpen={isAuthModalOpen} onClose={() => {dispatch(setIsAuthModalClose())}}><AuthForm/></Modal>
            <Modal isOpen={isAddressModalOpen} onClose={() => {dispatch(setIsAddressModalClose())}}><AddressSelectorForm/></Modal>
            <Modal isOpen={isCityModalOpen} onClose={() => {dispatch(setIsCityModalClose())}}><SelectCityForm/></Modal>
            <Modal isOpen={isAuthQuestionModalOpen} onClose={() => {dispatch(setIsAuthQuestionModalClose())}}><AuthQuestionForm/></Modal>

            <Message/>
            <Cookie/>
            <FloatingSocialButtons/>
        </Wrapper>
    );
};

export default Layout;
