import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { request } from '../../../utils/request';
import { sha256 } from '../../../utils/sha256';

export const sendPhone = createAsyncThunk(
    'profile/sendPhone',
    async (phone, {rejectWithValue }) => {
        try {
            const response = await request("ClientAuthService", "SendCodeToPhone", {
                phone
            });

            if (response.result) {
                return {
                    phone: phone,
                    session: response.data.session,
                };
            } else {
                return rejectWithValue('Ошибка извлечения данных');
            }
        } catch (error) {
            console.error(error);
            return rejectWithValue('Ошибка подключения');
        }
    }
);

export const sendCode = createAsyncThunk(
    'profile/sendCode',
    async (code, {rejectWithValue }) => {
        try {
            localStorage.setItem("codeHash", sha256(code));
            const response = await request("ClientAuthService", "CreateTokenBySession");

            if (response.result) {
                return {
                    token: response.data.token,
                    refreshToken: response.data.refreshToken,
                };
            } else {
                return rejectWithValue('Ошибка извлечения данных');
            }
        } catch (error) {
            console.error(error);
            return rejectWithValue('Ошибка подключения');
        }
    }
);

export const logout = createAsyncThunk(
    'profile/logout',
    async (_, { rejectWithValue }) => {
        try {
            const response = await request("ClientAuthService", "DeleteToken");

            if (response.result) {
                localStorage.removeItem("token");
                localStorage.removeItem("refreshToken");
                localStorage.removeItem("session");
                localStorage.removeItem("loginHash");
                localStorage.removeItem("codeHash");
                localStorage.removeItem("typeAuth");

                return response.data;
            } else {
                return rejectWithValue('Ошибка извлечения данных');
            }
        } catch (error) {
            console.error(error);
            return rejectWithValue('Ошибка подключения');
        }
    }
);

export const getProfile = createAsyncThunk(
    'profile/getProfile',
    async (_, { rejectWithValue }) => {
        try {
            const response = await request("AppDinnerController", "GetProfile");

            if (response.result) {
                return response.data;
            } else {
                return rejectWithValue('Ошибка извлечения данных');
            }
        } catch (error) {
            console.error(error);
            return rejectWithValue('Ошибка подключения');
        }
    }
);

export const saveProfile = createAsyncThunk(
    'profile/saveProfile',
    async (data, { rejectWithValue }) => {
        try {
            const response = await request("AppDinnerController", "SaveProfile", data);

            if (response.result) {
                return response.data;
            } else {
                return rejectWithValue('Ошибка извлечения данных');
            }
        } catch (error) {
            console.error(error);
            return rejectWithValue('Ошибка подключения');
        }
    }
);

const token = localStorage.getItem('token');
const isAuth = token ? true : false;

const initialState = {
    isAuthModalOpen: false,
    isAuthQuestionModalOpen: false,
    sendPhoneStatus: null,
    phone: null,
    sendCodeStatus: null,
    session: null,
    token: null,
    isAuth: isAuth,

    getProfileStatus: null,
    profile: null,
    saveProfileStatus: null,
};

const profileSlice = createSlice({
    name: 'profile',
    initialState,
    extraReducers: (builder) => {
        builder
            // sendPhone
            .addCase(sendPhone.pending, (state) => {
                state.sendPhoneStatus = 'pending';
            })
            .addCase(sendPhone.fulfilled, (state, action) => {
                state.sendPhoneStatus = 'fulfilled';
                state.phone = action.payload.phone;
                localStorage.setItem("loginHash", sha256(action.payload.phone));
                localStorage.setItem('session', action.payload.session);
            })
            .addCase(sendPhone.rejected, (state) => {
                state.sendPhoneStatus = 'rejected';
            })

            // sendCode
            .addCase(sendCode.pending, (state) => {
                state.sendCodeStatus = 'pending';
            })
            .addCase(sendCode.fulfilled, (state, action) => {
                state.sendCodeStatus = 'fulfilled';
                localStorage.setItem('token', action.payload.token);
                localStorage.setItem("refreshToken", action.payload.refreshToken);
                state.isAuth = true;
                state.isAuthModalOpen = false;
            })
            .addCase(sendCode.rejected, (state) => {
                state.sendCodeStatus = 'rejected';
            })

            // logout
            .addCase(logout.fulfilled, (state) => {
                state.isAuthModalOpen = false;
                state.sendPhoneStatus = null;
                state.phone = null;
                state.sendCodeStatus = null;
                state.session = null;
                state.token = null;
                state.isAuth = false;
                state.getProfileStatus = null;
                state.profile = null;
            })

            // getProfile
            .addCase(getProfile.pending, (state) => {
                state.getProfileStatus = 'pending';
            })
            .addCase(getProfile.fulfilled, (state, action) => {
                state.getProfileStatus = 'fulfilled';
                state.profile = action.payload;
            })
            .addCase(getProfile.rejected, (state) => {
                state.getProfileStatus = 'rejected';
            })

            // saveProfile
            .addCase(saveProfile.pending, (state) => {
                state.saveProfileStatus = 'pending';
            })
            .addCase(saveProfile.fulfilled, (state, action) => {
                state.saveProfileStatus = 'fulfilled';
                state.profile = {...state.profile, client: action.payload.client, settings: action.payload.settings};
            })
            .addCase(saveProfile.rejected, (state) => {
                state.saveProfileStatus = 'rejected';
            })
    },
    reducers: {
        setIsAuthModalOpen: (state) => {
            state.isAuthModalOpen = true;
            state.isAuthQuestionModalOpen = false;
        },
        setIsAuthModalClose: (state) => {
            state.isAuthModalOpen = false;
            state.phoneSend = false;
            state.errorPhone = false;
            state.errorPhoneServer = false;
            state.sendPhoneStatus = null;
            state.userPhone = null;
            state.sendCodeStatus = null;
        },
        setIsAuthQuestionModalOpen: (state) => {
            state.isAuthQuestionModalOpen = true;
        },
        setIsAuthQuestionModalClose: (state) => {
            state.isAuthQuestionModalOpen = false;
        },
        resetAuthState: (state) => {
            state.sendPhoneStatus = null;
            state.userPhone = null;
            state.sendCodeStatus = null;
        },
    },
});

export const {
    setIsAuthModalOpen,
    setIsAuthModalClose,
    setIsAuthQuestionModalOpen,
    setIsAuthQuestionModalClose,
    resetAuthState,
} = profileSlice.actions;

export default profileSlice.reducer;
