import React from "react";
import styled from "styled-components";
import { Container } from "../../core/styles";
import Subscribe from "./components/Subscribe";
import Button from "../../components/Button";

import mastercard from "./images/card/mastercard.png";
import visa from "./images/card/visa.png";
import mir from "./images/card/mir.png";

import googlePlay from './images/social/googleplay.png';
import appStore from './images/social/appstore.png';

const Footer = () => {
  const Links = [
    {
      title: 'Сытый Офис',
      links: [
        { name: 'О нас', url: '/about' },
        { name: 'Меню', url: '/' },
        { name: 'Оплата', url: '/pay' },
        { name: 'Доставка', url: '/delivery' },
        { name: 'Для руководителей', url: '/manager' },
      ]
    },
    {
      title: 'Соц. сети',
      links: [
        { name: 'Instagram', url: 'https://www.instagram.com/sitiy_tlt/' },
        { name: 'Вконтакте', url: 'https://vk.com/sitiy_ru' },
        { name: 'Facebook', url: 'https://www.facebook.com/sitiyofiss/' },
        { name: 'Telegram', url: 'https://t.me/sitiy_ofis' },
      ]
    },
    {
      title: 'Цифры',
      links: [
        { name: 'Заказов сегодня', value: '3560' },
        { name: 'Блюд и напитков', value: '9 186' },
        { name: 'Столовых', value: '93' },
        { name: 'Города', value: '7' },
      ]
    },
  ];

  return (
    <>
      <Subscribe/>
      <Wrapper>
        <Container>
          <Flex>
            <LinksWrapper>
              {Links.map((column, index) => (
                <LinksCol key={index}>
                  <ColTitle>{column.title}</ColTitle>
                  <ul>
                    {column.links.map((link, idx) => (
                      <li key={idx}>
                        {link.url ? (
                          <a href={link.url} target="_blank" rel="noopener noreferrer">{link.name}</a>
                        ) : (
                          <span>{link.name}: {link.value}</span>
                        )}
                      </li>
                    ))}
                  </ul>
                </LinksCol>
              ))}
            </LinksWrapper>

            <InfoWrapper>
              <Review>
                <ColTitle>Качество работы</ColTitle>
                <Button size='small'>Оставить отзыв</Button>
              </Review>

              <Info>
                <Phone>8 800 200-69-24</Phone>
                <PhoneLabel>звонок бесплатный</PhoneLabel>
                <PaymentIcons>
                  <img style={{height: '16px'}} src={mir} alt="МИР" />
                  <img style={{height: '16px'}} src={visa} alt="Visa" />
                  <img style={{height: '20px'}} src={mastercard} alt="MasterCard" />
                </PaymentIcons>
                <AppStores>
                  <a
                    href="https://play.google.com/store/apps/details?id=air.com.menu4me.dinner.sitiyoffice&utm_source=my_comp&utm_medium=cpa&utm_campaign=promo&utm_content=landing_page&utm_term=just"
                    target="_blank"
                    rel= "noopener noreferrer"
                  >
                    <img src={googlePlay} alt="Google Play" />
                  </a>
                  <a
                    href="https://apps.apple.com/ru/app/%D1%81%D1%8B%D1%82%D1%8B%D0%B9-%D0%BE%D1%84%D0%B8%D1%81-%D0%B4%D0%BE%D1%81%D1%82%D0%B0%D0%B2%D0%BA%D0%B0-%D0%BE%D0%B1%D0%B5%D0%B4%D0%BE%D0%B2/id1361986950"
                    target="_blank"
                    rel= "noopener noreferrer"
                  >
                    <img src={appStore} alt="App Store" />
                  </a>
                </AppStores>
              </Info>
            </InfoWrapper>
          </Flex>
        </Container>

        <Line/>

        <Container>
          <FooterNote>
            © Сытый Офис - Сервис доставки еды 2018-{new Date().getFullYear()}
          </FooterNote>
        </Container>
      </Wrapper>
    </>
  );
};

export default Footer;

const Wrapper = styled.div`
  padding: 65px 0;
  background: #ffffff;
`;

const Flex = styled.div`
  display: flex;
  justify-content: center;
  gap: 32px;
  flex-wrap: wrap;
`;

const ColTitle = styled.div`
  margin-bottom: 16px;
  font-size: 16px;
  font-weight: 600;
`;

const LinksWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 32px;
  flex: 1;
  
  @media(max-width: 500px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const LinksCol = styled.div`
  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      margin-bottom: 8px;
    }

    a, span {
      font-size: 14px;
      color: #333;
      text-decoration: none;
    }
  }
`;

const InfoWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 32px;
  flex: 1;
  
  @media(max-width: 500px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const Review = styled.div`
  display: flex;
  flex-direction: column;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 16px;
`;

const Phone = styled.div`
  font-size: 24px;
`;

const PhoneLabel = styled.div`
  font-size: 14px;
  color: #333;
`;

const PaymentIcons = styled.div`
  display: flex;
  gap: 8px;
`;

const AppStores = styled.div`
  display: flex;
  gap: 16px;

  img {
    height: 40px;
  }
`;

const Line = styled.div`
  margin: 32px 0 24px;
  width: 100%;
  height: 1px;
  background: #EEEEEE;
`;

const FooterNote = styled.div`
  font-size: 14px;
  text-align: center;
`;
