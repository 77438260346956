import React from "react";
import { render } from "react-dom";
import {BrowserRouter} from "react-router-dom";
import { Provider } from "react-redux";
// import store from "./store";
import { store } from './redux/configureStore';
import * as serviceWorker from "./utils/serviceWorker";
import "./utils/date";
import "./utils/window";
import 'react-app-polyfill/ie11';
import moment from 'moment';
import 'moment/locale/ru';
import App from "./core/App";

moment.locale('ru');

const currentAppVersion = '1.00.00';
const storedAppVersion = localStorage.getItem('appVersion');

if (storedAppVersion !== currentAppVersion) {
	localStorage.clear();
	localStorage.setItem('appVersion', currentAppVersion);
	document.location.reload();
}

localStorage.removeItem('payProcessing');

if (document.location.protocol === 'https:') {
	caches.keys().then(function(names) {
		for (let name of names) {
			console.log(name, 'cache name')
			caches.delete(name).then(res => console.log('cache is cleared?', res));
		}
	})
}

render(
	<Provider store={store}>
		<BrowserRouter basename={"/"}>
			<App/>
		</BrowserRouter>
	</Provider>,
	document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
